window['global'] = window;

import 'core-js/es/global-this';
import 'core-js/es/array/at';
import 'core-js/es/object/from-entries';
import 'core-js/es/object/has-own.js';
import 'core-js/es/string/match-all';
import 'core-js/web/queue-microtask';
import 'intersection-observer';
import 'web-animations-js';

/* istanbul ignore if */
if (typeof ResizeObserver === 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  global.ResizeObserver = require('resize-observer-polyfill').default;
}
